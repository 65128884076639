import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface Task {
  category_id: number;
  task_id: number;
  description: string;
  image_url: string;
  location_id: number;
  room_id: number;
}
interface CfOperations {
  companyInfo: any;
  step: number;
  staffService: StaffService | null;
  staffTasks: StaffServiceItem[];
  taskToAssign: Task;
  tableName: string;
}

const initialState: CfOperations = {
  companyInfo: null,
  step: 1,
  staffService: null,
  staffTasks: [],
  taskToAssign: {
    category_id: 0,
    task_id: 0,
    description: "",
    image_url: "",
    location_id: 0,
    room_id: 0,
  },
  tableName: "",
};

const cfOperations = createSlice({
  name: "cfOperations",
  initialState,
  reducers: {
    insertCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    updateStep: (state, action) => {
      state.step = action.payload;
    },
    insertStaffOpService: (state, action) => {
      state.staffService = action.payload;
    },
    insertStaffOpTasks: (state, action) => {
      state.staffTasks = action.payload;
    },
    insertTaskToAssign: (state, action) => {
      state.taskToAssign = action.payload;
    },
    insertTableName: (state, action) => {
      state.tableName = action.payload;
    },
  },
});

export const getStep = (state: RootState) => state.cfOperations.step;

export const getStaffOpService = (state: RootState) =>
  state.cfOperations.staffService;

export const getStaffOpTasks = (state: RootState) =>
  state.cfOperations.staffTasks;

export const getTaskToAssign = (state: RootState) => {
  return state.cfOperations.taskToAssign;
};

export const getCompanyInfo = (state: RootState) => {
  return state.cfOperations.companyInfo;
};

export const getTableName = (state: RootState) => state.cfOperations.tableName;

export const {
  updateStep,
  insertStaffOpService,
  insertStaffOpTasks,
  insertTaskToAssign,
  insertCompanyInfo,
  insertTableName,
} = cfOperations.actions;

export default cfOperations.reducer;
